import React from 'react';
import { graphql } from 'gatsby';
import { arrayOf, shape, object } from 'prop-types';

import ScrollToTop from '../components/Elements/ScrollToTop';
import Layout from '../components/Layout/Layout';
import BlogPostsGrid from '../components/Sections/Blog/BlogPostsGrid';
import SEO from '../components/SEO/SEO';
import { BlogPostType, TagType } from '../types';

import '../fragments/node/blogPostTeaser';

const Tag = ({ data, location }) => {
  const { tag, blogPosts } = data;

  return (
    <Layout className="blog" location={location}>
      <SEO metatags={tag?.metatags} />
      <section className="section">
        <div className="container">
          <h1>{tag.name}</h1>
          <BlogPostsGrid posts={blogPosts?.nodes || []} />
        </div>
      </section>
      <ScrollToTop />
    </Layout>
  );
};

export default Tag;

Tag.propTypes = {
  data: shape({
    tag: TagType,
    blogPosts: shape({ nodes: arrayOf(BlogPostType) }),
  }),
  location: object
};

export const tagQuery = graphql`
  query tagQuery($drupalId: String!) {
    tag: taxonomyTermTags(drupal_id: { eq: $drupalId }) {
      name
    }
    blogPosts: allNodeBlogPost(
      filter: { relationships: { field_tags: { elemMatch: {drupal_id: { in: [$drupalId] } } } } },
      sort: { fields: created, order: DESC },
    ) {
      nodes {
        ...NodeBlogPostTeaser
      }
    }
  }
`;
